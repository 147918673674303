const entries = [
  {
    title: '私の好きなもの／こと',
    date: '2/15/2021',
    text: 'コニチワ！私の一番好きなたべもは日本のたべものです。ラメンとすしは大好きで、私はすしの方が好きです。マグロはおいしいので、私の一番好きなさかなはマグロです。そして、うなぎをたべるのが好きです。サウスベンドはうみのちかくないので、ここにすしをあまりたべません。私の一番好きなアニメは新世紀エヴァンゲリオン。エヴァンゲリオンはとてもおもしろいので、とてもゆうめいです。そして、エヴァンゲリオンはとても古いです。今に進撃の巨人と呪術廻戦をみます。すごいですよ！私の一番好きなスポーツはサツカーです。私はサツカーをみるのとするのが大好きです。私の一番好きなテムはアースノル！アースノルはとてもいいので、みるのがとても好きです。私の一番好きなせんしゆはアツバメヤングです。何が好きですか。'
  },
  {
    title:'私のふゆやすみ',
    date: '2/8/2021',
    text: 'こんにちわ！ 私のふゆやすみはよかたです！さいしょにカリフォルニアのサンフランシスコにかけましておねさんのアパートにいきました。ばんごはんにいしょにりょりおしてたべました。スピイクバルおあそびました。それから私のかざくはハワイイにいきました。ハワイイにいきましたか？とてもたのしくてきりえでした！おにさんはシナガポルにからきました。いしょにうみにいつておよぎました。そして山にハイキングおしました。月のちうちにかえりましてともだちにきました。いしょにりょこおしてスキーおして。ミルヴァレに好きなレストランにばんごはんおたべてびるおのみました。ビグサーにいてしゃしんおとりました。サンフランシスコにヅムパリングバービにばんごはんおたべました。おいしかつたです！それからノートルダム大学にかけました！'
  },
  {
    title: '授業',
    date: '8/23/2020',
    text: 'こにちわ！いまわじゅじにじゅぷんです。先週の授業が始まりました。ネットワークの授業わおもしろいです！そして、プログラムパルダイムスと古代日本わたのしです。でも、コンピューティングの理論と倫理の授業わ少しつまらない。私の授業わすべてオンラインデス。私の先生わとてもいいですよ！'
  },
  {
    title: '自己紹介',
    date: '8/9/2020',
    text: 'はじめまして！ルーチマンです。ノートルダム大学の四年生です。専攻わコンピューター科学です。サンフランシスコにからきました, でも今はサウスベンドに住んでいます。どおぞよろしく！'
  },
]

export default entries;
